import { Typography } from '@mui/material'
import {
  DataGrid,
  GridColumns,
  GridEnrichedColDef,
} from '@mui/x-data-grid'
import moment from 'moment'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchAdmin } from '../../services/fetch'
import { numberFormat } from '../../services/number'
import { initColumns, columnGroupingModel } from '../../constants/kpiConstants'
import { SearchForm } from './SearchForm'
import { createLinkDau } from './KpiDBPersonalizeDau'

interface FormData {
  from: moment.Moment | null
  to: moment.Moment | null
  timezone: string
  storeCountryCode: string
  daysAfter: number | null
}

const initStatus = {
  columns: [] as GridColumns,
  rows: [],
  loading: false,
  columnVisibilityModel: generateColumnVisibilityModel(),
}

export function generateColumnVisibilityModel() {
  const columnCategories = [
    'id',
    'updateFullName',
    'updateAvatarUrl',
    'updateBio',
    'moment',
    'moment_global',
    'moment_friend',
    'moment_like',
    'moment_comment',
    'moment_like_global',
    'moment_like_friend',
    'moment_comment_global',
    'moment_comment_friend',
    'chatroom',
    'chatroom_join',
    'chatroom_leave',
    'chatMessage',
    'friend_request',
    'friend_accept',
    'contactsSyncBanner',
    'phonebook',
    'sendSMS',
  ]
  const suffixes = ['', '_ppl', '_cnt', '_daysAfter', '_daysAfter_ppl', '_daysAfter_cnt', '_rate', '_ratio', '_daysAfter_ratio', '_global', '_friend', '_global_ppl', '_friend_ppl', '_global_ratio', '_friend_ratio']

  const model: { [key: string]: boolean } = {}
  columnCategories.forEach((category) => {
    suffixes.forEach((suffix) => {
      model[`${category}${suffix}`] = false
    })
  })
  return model
}

function KpiDBPersonalize() {
  const [status, setStatus] = useState(() => {
    const savedModel = localStorage.getItem('columnVisibilityModel')
    return {
      ...initStatus,
      columnVisibilityModel: savedModel
        ? JSON.parse(savedModel)
        : generateColumnVisibilityModel(),
    }
  })

  const updateStatus = (newStatus: any) => {
    setStatus((old) => {
      const updatedVisibility = newStatus.columnVisibility
        ? { ...old.columnVisibilityModel, ...newStatus.columnVisibility }
        : old.columnVisibilityModel

      localStorage.setItem('columnVisibilityModel', JSON.stringify(updatedVisibility))

      return {
        ...old,
        ...newStatus,
        columnVisibilityModel: updatedVisibility,
      }
    })
  }

  const onColumnVisibilityModelChange = (newModel: Record<string, boolean>) => {
    setStatus((old) => {
      const updatedStatus = {
        ...old,
        columnVisibilityModel: newModel,
      }
      localStorage.setItem('columnVisibilityModel', JSON.stringify(newModel))
      return updatedStatus
    })
  }

  return (
    <DataGrid
      loading={status.loading}
      columns={status.columns}
      rows={status.rows}
      columnVisibilityModel={status.columnVisibilityModel}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      components={{
        Toolbar: SearchForm,
      }}
      componentsProps={{
        toolbar: {
          updateStatus,
          initColumns,
          currentVisibility: status.columnVisibilityModel,
        },
      }}
      disableColumnFilter
      disableColumnMenu
      hideFooter
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          overflowWrap: 'break-word',
          wordBreak: 'break-all',
          lineHeight: '1rem',
          whiteSpace: 'normal',
        },
        '.headerBorder': {
          borderLeft: 'solid 1px rgba(0, 0, 0, 1) !important',
          borderRight: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.borderLeft': {
          borderLeft: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.borderRight': {
          borderRight: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.bgDays': {
          backgroundColor: '#7fffff',
        },
        '.bgDaysPpl': {
          backgroundColor: '#7fbfff',
        },
        '.bgDaysCnt': {
          backgroundColor: '#7f7fff',
        },
        '.bgDaysAfter': {
          backgroundColor: '#7fff7f',
        },
        '.bgDaysAfterPpl': {
          backgroundColor: '#bfff7f',
        },
        '.bgDaysAfterCnt': {
          backgroundColor: '#7fffbf',
        },
        '& .MuiDataGrid-cell:empty': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader--empty': {
          display: 'none',
        },
      }}
      showColumnRightBorder
      showCellRightBorder
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={columnGroupingModel}
    />
  )
}

function Cell(props: { value: number, bottom?: number | { [key: string]: number } }) {
  const { value, bottom } = props

  if (typeof value === 'object') {
    const { countUser, countEvent } = value

    return (
      <Typography variant="body2" align="right">
        {countUser}
        <Typography variant="caption"> ppl</Typography>
        <br />
        {countEvent}
        <Typography variant="caption"> cnt</Typography>
      </Typography>
    )
  }

  return (
    <Typography variant="body2">
      {(value || value === 0) ? numberFormat(value) : '0'}
    </Typography>
  )
}

export async function fetchData({
  formData,
  navigate,
}: {
  formData: FormData
  navigate: ReturnType<typeof useNavigate>
}) {
  const columns: GridColumns = []

  if (!formData.from || !formData.to) {
    throw new Error('Invalid date range: "from" and "to" fields must not be null.')
  }

  formData.from.toJSON = () => formData.from!.format('YYYYMMDD')
  formData.to.toJSON = () => formData.to!.format('YYYYMMDD')

  const rows = await fetchAdmin({
    path: 'analytics/kpi/db',
    method: 'GET',
    queries: {
      form: JSON.stringify(formData),
    },
  })
    .then((res) => res.json())
    .catch(() => {})

  initColumns.forEach((col) => {
    columns.push(initGridColumn(col, formData, navigate))
  })

  return {
    columns,
    rows,
  }
}

function initGridColumn(column: GridEnrichedColDef, formData: FormData, navigate: ReturnType<typeof useNavigate>): GridEnrichedColDef {
  const customRenderers: Record<string, (params: any) => React.ReactNode> = {
    // add any custom column to make it link or etc
    dau: ({ value, row }) => createLinkDau(value, row, formData, navigate),
  }
  return {
    ...column,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    cellClassName: 'borderRight',
    renderCell: customRenderers[column.field] || column.renderCell
      || (({ value }) => <Cell value={value} />),
    hideSortIcons: true,
  }
}

export default KpiDBPersonalize
